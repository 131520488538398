// import { loginRequest } from "../../../../config/auth-config";
import Carousel from "nuka-carousel";
import style from "./login.module.scss";
import { MasterFooter } from "../../../layouts";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../features/auth/hooks/useAuth";

export const Login = () => {
  const { t } = useTranslation();
  const { login } = useAuth();

  const defaultControlsConfig = { pagingDotsClassName: "hm-paging-dot" };

  return (
    <>
      <div className="flex flex-row relative h-5/6 w-3/4 m-auto">
        <div
          className={`${style.radiusCard} w-3/4 m-10   flex flex-col text-center justify-center  bg-white p-5`}
        >
          <img
            src="/assets/images/Karibu.svg"
            alt="Thank you 2"
            className={`${style.loginLogo}`}
          />
          <h3 className={`${style.loginTitle}`}>
            {t("modules.login.welcome")}
          </h3>
          <button
            onClick={login}
            className={`${style.whiteButton} min-h-16 w-2/3 m-auto group relative  justify-center border border-[#80808061] mb-5 text-2xl font-medium rounded-md text-yardDeepBlue hover:bg-yardBackgroundDark hover:text-white focus:outline-none mt-20`}
          >
            <LockIcon />
            &nbsp;&nbsp; {t("modules.login.title")}
          </button>
          <span className={`${style.loginSubTitle}`}>
            {t("modules.login.other-account")}
          </span>
        </div>

        <div className={`${style.box} ${style.content} `}>
          <>
            <Carousel
              withoutControls={false}
              disableAnimation={false}
              adaptiveHeight={true}
              defaultControlsConfig={defaultControlsConfig}
              renderCenterLeftControls={() => {
                return <></>;
              }}
              renderCenterRightControls={() => {
                return <></>;
              }}
              autoplay={true}
            >
              <div
                className={`${style.trackOrderContainer} p-9 justify-center  text-center`}
              >
                <h4 className={`${style.trackOrderTitle}`}>
                  {t("modules.login.info.buy-cement")}
                </h4>
                <img
                  src="/assets/images/illustration-landing.png"
                  alt="Thank you 2"
                />
                <br />
                <span className={`${style.trackOrderDetails}`}>
                  {t("modules.login.info.delivery-phrase1")} <br />
                  {t("modules.login.info.delivery-phrase2")}
                  <br />
                  {t("modules.login.info.delivery-phrase3")}
                </span>
              </div>
              <div
                className={`${style.trackOrderContainer} p-9 justify-center text-center`}
              >
                <h4 className={`${style.trackOrderTitle}`}>
                  {t("modules.login.info.order-tracking")}
                </h4>
                <img
                  src="/assets/images/illustration-landing.png"
                  alt="Thank you 2"
                  className="justify-center text-center"
                />
                <br />
                <span className={`${style.trackOrderDetails}`}>
                  {t("modules.login.info.delivery-phrase4")} <br />
                  {t("modules.login.info.delivery-phrase5")} <br />
                  {t("modules.login.info.delivery-phrase6")}
                </span>
              </div>
              <div
                className={`${style.trackOrderContainer} p-9 justify-center text-center`}
              >
                <h4 className={`${style.trackOrderTitle}`}>
                  {t("modules.login.info.order-history")}
                </h4>
                <img
                  src="/assets/images/illustration-landing.png"
                  alt="Thank you 2"
                />{" "}
                <br />
                <span className={`${style.trackOrderDetails}`}>
                  {t("modules.login.info.delivery-phrase7")} <br />
                  {t("modules.login.info.delivery-phrase8")} <br />
                  {t("modules.login.info.delivery-phrase9")}
                </span>
              </div>
            </Carousel>
          </>
        </div>
      </div>
      <div className=" absolute bottom-0 w-full ">
        <MasterFooter />
      </div>
    </>
  );
};
