import { BrowserRouter } from "react-router-dom";
import PermissionProvider from "../providers/permissions/permission-providers";
import { createContext, useState, useMemo } from "react";
import UserPermissionService from "../providers/permissions/user-permissions-service";
import { AllRoutes } from "./AllRoutes";
import { AuthContextProvider } from "../providers/auth-context/auth-context-provider";

export interface PageLoadingContextInterface {
  pageLoading: boolean;
  setPageLoading?: (loading: boolean) => void;
}
export const PageLoadingContext = createContext<PageLoadingContextInterface>({
  pageLoading: false,
});
const PageLoadingContextProvider = ({ children }) => {
  const [pageLoading, setPageLoading] = useState(false);

  return (
    <PageLoadingContext.Provider value={{ pageLoading, setPageLoading }}>
      {children}
    </PageLoadingContext.Provider>
  );
};

const AppRoutes = () => {
  const ps = UserPermissionService();

  const [permissions, setPermissions] = useState<string[] | undefined>(
    ps.permissions
  );

  useMemo(() => {
    setPermissions(ps.permissions);
  }, [ps.permissions, setPermissions]);

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <PageLoadingContextProvider>
          <PermissionProvider permissions={permissions}>
            <AllRoutes></AllRoutes>
          </PermissionProvider>
        </PageLoadingContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
};

export { AppRoutes, PageLoadingContextProvider };
