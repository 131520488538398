import ObjectAsFields, {
  FieldConfigValue,
} from "../object-as-fields/object-as-fields";
import SideBoxFooter from "../sidebox-footer/sidebox-footer";
import SideBoxHeader from "../sidebox-header/sidebox-header";
import OperationType from "../types/operation-type-enum";
import FormikFormField from "./FormikFormField";
import { JSX } from "react";

export interface FormFieldOption {
  fieldName: string;
  labelName: string;
  type?:
  | "text"
  | "number"
  | "date"
  | "time"
  | "dateTime"
  | "licensePlateNo"
  | "radio"
  | "boolean"
  | "companyFormSelect"
  | "customerSegmentFormSelect"
  | "productFormSelect"
  | "countryFormSelect"
  | "locationFormSelect"
  | "productMeasurementFormSelect"
  | "customerFormSelect"
  | "customerRoleFormSelect"
  | "unitOfMeasureFormSelect"
  | "file"
  | "richText"
  | "userFormSelect"
  | "vehicleFormSelect"
  | "descriptionFormSelect"
  | "locationTypeSelect"
  | "color";
  intialId?: number;
  parentId?: number;
  ignoreSelectedLocation?: boolean;
  selectedLocationId?: number;
  idFilterList?: number[];
  radioValues?: { label: string; value: any }[];
  customElement?: JSX.Element;
  disabled?: boolean;
  placeHolder?: string;
  textRows?: number;
  format?: "ISO8601" | "default";
  selectItemChanged?: (value, text) => void;
  colspan?: boolean;
  defaultValue?: string;
}

interface FormikFormBuilderProps<T> {
  formik?: any;
  operationType: OperationType | undefined;
  loading: boolean;
  itemName: string;
  selectedItem: T;
  showHeader?: boolean;
  excludeFields?: string[] | undefined;
  fieldConfig?: { [Property in keyof T]?: FieldConfigValue };
  onStartEditClicked?: () => void;
  onDeleteClicked?: () => void;
  onClose?: () => void;
  fieldOptions: FormFieldOption[];
  formClassName?: string;
  allowEdit?: boolean;
  editProps?: {
    editButtonText?: string;
    editScreenTitle?: string;
    customEditHeading?: JSX.Element;
    editScreenFormikObject?: any;
  };

  grid?: boolean;
}

function FormikFormFieldBulder<T>({
  formik,
  operationType,
  loading,
  itemName,
  selectedItem,
  onStartEditClicked,
  onDeleteClicked,
  onClose,
  formClassName,
  showHeader = true,
  fieldConfig,
  fieldOptions,
  excludeFields,
  allowEdit = true,
  editProps = {
    editScreenFormikObject: undefined,
    editScreenTitle: undefined,
    editButtonText: undefined,
    customEditHeading: <></>,
  },
  grid = false,
}: FormikFormBuilderProps<T>) {
  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  console.log("--->", selectedItem, fieldConfig, excludeFields);
  return (
    <>
      {showHeader && (
        <SideBoxHeader
          operationType={operationType}
          editScreenTitle={editProps.editScreenTitle}
          itemName={itemName}
          onClose={onClose}
        />
      )}
      <form
        className={`${formClassName}`}
        onKeyDown={onKeyDown}
        onSubmit={(e) => {
          e.preventDefault();
          if (
            operationType === OperationType.update &&
            editProps?.editScreenFormikObject
          ) {
            editProps?.editScreenFormikObject?.submitForm();
          } else formik?.submitForm();
        }}
      >
        {(operationType === OperationType.update ||
          operationType === OperationType.create) && (
            <div
              className={`card mt-0 ${grid ? "grid grid-cols-2 gap-4" : "tpr-form"
                } bg-white`}
            >
              {fieldOptions.map((option) => {
                return (
                  <div
                    key={option.fieldName}
                    className={
                      option.colspan != null ? "col-start-1 col-end-3" : ""
                    }
                  >
                    {option?.customElement && option.customElement}
                    {!option?.customElement && (
                      <FormikFormField
                        key={option.fieldName}
                        formik={
                          operationType === OperationType.update &&
                            editProps?.editScreenFormikObject
                            ? editProps.editScreenFormikObject
                            : formik
                        }
                        fieldOption={option}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}

        {(operationType === OperationType.read ||
          operationType === OperationType.delete) && (
            <div className="p-4">
              <ObjectAsFields
                obj={selectedItem}
                fieldConfig={fieldConfig}
                excludeFields={excludeFields}
              />
            </div>
          )}

        <SideBoxFooter
          allowEdit={allowEdit}
          editButtonText={editProps.editButtonText}
          resetForm={formik?.resetForm}
          isBusy={loading}
          itemName={itemName}
          onClose={onClose}
          onStartEditClicked={onStartEditClicked}
          onDeleteClicked={onDeleteClicked}
          operationType={operationType}
        />
      </form>
    </>
  );
}

export default FormikFormFieldBulder;
