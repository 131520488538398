import { useContext, useEffect, useState } from "react";
import { WithChildren } from "../../../helpers/with-children";
import { SplashScreen } from "./SplashScreen";
import { PageLoadingContext } from "src/app/routing/AppRoutes";

export const LayoutInit = ({ children }: WithChildren) => {
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const pageLoadingContext = useContext(PageLoadingContext);

  useEffect(() => {
    if (!!pageLoadingContext.pageLoading) {
      console.log(pageLoadingContext.pageLoading);
      setShowSplashScreen(true);
    } else {
      console.log(pageLoadingContext.pageLoading);
      setShowSplashScreen(false);
    }
  }, [pageLoadingContext.pageLoading]);

  return showSplashScreen ? <SplashScreen /> : <>{children}</>;
};
